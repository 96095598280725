const dev = {
  BASE_URL: "http://localhost:3000/",

  // API_ENDPOINT_URL: "https://booking.kretosstechnology.com/api",
  API_ENDPOINT_URL: "http://localhost:3017/api",
};
const prod = {
  BASE_URL: "http://localhost:3000/",

  API_ENDPOINT_URL: "https://booking.kretosstechnology.com/api",
  // API_ENDPOINT_URL: "http://localhost:3017/api",
};

const test = {
  // API_ENDPOINT_URL: "http://localhost:3006/api/v1",
  BASE_URL: "http://localhost:3000/",

  // API_ENDPOINT_URL: "https://booking.kretosstechnology.com/api",
  API_ENDPOINT_URL: "http://localhost:3017/api",
};

const getEnv = () => {
  console.log("process.env.NODE_ENV = ", process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
