import React, {useEffect, useRef, useState} from 'react';
import {
  Link,
  useParams
} from 'react-router-dom';
import moment from 'moment';
import PageTitle from '../../layouts/PageTitle';
import PmService from "../../../services/PmService";
import bigimg from '../../../images/big/img1.jpg'

const order_detail_style = {
  width:"40%",
}
const OrderDetails = () => {
  const { id } = useParams();
  const orderId = atob(id);
  const [loader, setLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [guestInformationDetails, setGuestInformationDetails] = useState({});

  useEffect(() => {
    setLoader(true);
    PmService.GetOrderDetail(orderId)
      .then((resp) => {
        console.log("Respo = ", resp);
        if (resp.success) {
          setOrderDetails(resp.data.order);
          setPropertyDetails(resp.data.property);
          setUserDetails(resp.data.user);
          setGuestInformationDetails(resp.data.guest_information);
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      })
      .finally(() => {
        setLoader(false);
      })

      // getMainServicesByProeprty();
    }, []);
  
 useEffect(() => {
   console.log(orderDetails);
 }, [orderDetails]);
  
  const getStatusClass = (status) => {
    switch (status) {
      case 'completed':
        return 'text-success';
      case 'created':
        return 'text-info';
      case 'accepted':
        return 'text-success';
      case 'rejected':
        return 'text-danger';
      case 'claimed':
        return 'text-dark';
      case 'refunded':
        return 'text-dark';
      default:
        return 'text-primary';
    }
  };
    return (
        <>
            <PageTitle motherMenu={"Orders"} activeMenu={"Detail"} />   
            <div className="row gx-lg-3">
                <div className="col-xl-12 col-xxl-12">
                    <div className="row gx-lg-3">
                        <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                            <div className="card overflow-hidden" style={{height:"auto"}}>
                                <div className="text-center p-3 overlay-box " style={{backgroundImage: `url(${bigimg})`}}>
                                    <div className="profile-photo">
                                        <img src={userDetails.profile_image} width="100" className="img-fluid rounded-circle" alt="/" />
                                    </div>
                                    <h3 className="mt-3 mb-1 text-white">Customer</h3>
                                    {/* <p className="text-white mb-0">Senior Manager</p> */}
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Name</span> <strong className="text-black"> {userDetails.first_name}  {userDetails.last_name} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Email</span> <strong className="text-black"> {userDetails.email} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Phone</span> <strong className="text-black"> {userDetails.telephone} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Gender</span> <strong className="text-black"> {userDetails.gender} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Nationality</span> <strong className="text-black"> {userDetails.nationality} </strong>
                                    </li>
                                </ul>
                                {/* <div className="card-footer border-0 mt-0 border-top">								
                                    <ul className="property-social">
                                        <li><Link to={"#"}><i className="lab la-instagram"/></Link></li>
                                        <li><Link to={"#"}><i className="lab la-facebook-f"/></Link></li>
                                        <li><Link to={"#"}><i className="lab la-twitter"/></Link></li>
                                    </ul>	
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-sm-12">
                            <div className="card overflow-hidden">
                                <div className="card-header">
                                    <h4 className="card-title">Order Detail</h4>
                                </div>
                                <div className="card-body row">
                                  <div className='col-md-6'>
                                    <table className="check-tbl mb-2">
                                        <tbody>
                                            <tr key="1">
                                              <td style={order_detail_style}><i className="" /> Order ID :</td>
                                              <td className="tb-para">{orderDetails.UID}</td>
                                            </tr>
                                            <tr key="1" >
                                                <td style={order_detail_style}><i className="" /> Ordered At :</td>
                                                <td className="tb-para">{ moment(orderDetails.created_at).format('DD-MM-YYYY, hh:mm A')}</td>
                                            </tr>
                                            <tr key="1" >
                                                <td style={order_detail_style}><i className="" /> Property :</td>
                                                <td className="tb-para" > <Link to={`./../property-details/${btoa(propertyDetails.id)}`}>{propertyDetails.name}</Link></td>
                                            </tr>
                                            <tr key="1"><td style={order_detail_style}>
                                                <i className="" /> Service :</td>
                                                <td className="tb-para"  >{orderDetails.service_title}</td>
                                            </tr>
                                            <tr key="1">
                                                <td ><i className="" /> Service Started at:</td>
                                                <td className="tb-para" >{moment(orderDetails.service_start_date).format('DD-MM-YYYY, hh:mm A')}</td>
                                            </tr>
                                            <tr key="1">
                                                <td ><i className="" /> Service End at :</td>
                                                <td className="tb-para" >{moment(orderDetails.service_end_date).format('DD-MM-YYYY, hh:mm A')}</td>
                                            </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> Start Time :</td>
                                                <td className="tb-para">{orderDetails.start_time}</td>
                                            </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> End Time :</td>
                                                <td className="tb-para">{orderDetails.end_time}</td>
                                            </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> Service Qty :</td>
                                                <td className="tb-para">{orderDetails.qty}</td>
                                            </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> Service Price :</td>
                                                <td className="tb-para">€{orderDetails.price}</td>
                                            </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> Claim comment :</td>
                                                <td className="tb-para">{orderDetails.complaint}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                  <div className='col-md-6'>
                                    <table className="check-tbl mb-2">
                                        <tbody>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" />Service Used for :</td>
                                                <td className="tb-para">{orderDetails.days} Days </td>
                                            </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> Total Amount :</td>
                                                <td className="tb-para">€{orderDetails.total_amount}</td>
                                            </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> Status:</td>
                                                <td className={getStatusClass(orderDetails.status)}>{orderDetails.status}</td>
                                                </tr>
                                            <tr key="1">
                                                <td style={order_detail_style}><i className="" /> Notes:</td>
                                                <td className="tb-para">{orderDetails.notes}</td>
                                            </tr>
                                            <tr key="1">
                                              <td style={order_detail_style}><i className="" /> Ratings:</td>
                                              <td className="tb-para">{orderDetails.ratings ? orderDetails.ratings:'-'}</td>
                                            </tr>
                                            <tr key="1">                    
                                              <td style={order_detail_style}><i className="" /> Review:</td>
                                              <td className="tb-para">{orderDetails.review?orderDetails.review:'-'}</td>
                                            </tr> 
                                            <tr key="1">
                                              <td style={order_detail_style}><i className="" /> Review - delivery time:</td>
                                              <td className="tb-para">{orderDetails.delivery_time?orderDetails.delivery_time:'-'}</td>
                                            </tr> 
                                            <tr key="1">
                                              <td style={order_detail_style}><i className="" /> Review - product quality:</td>
                                              <td className="tb-para">{orderDetails.product_quality?orderDetails.product_quality:'-'}</td>
                                            </tr> 
                                            <tr key="1">
                                              <td style={order_detail_style}><i className="" /> Review - customer service:</td>
                                              <td className="tb-para">{orderDetails.customer_service?orderDetails.customer_service:'-'}</td>
                                            </tr> 
                                            <tr key="1">
                                              <td style={order_detail_style}><i className="" /> Review at:</td>
                                              <td className="tb-para">{orderDetails.review_at?moment(orderDetails.review_at).format('DD-MM-YYYY, hh:mm A'):'-'}</td>
                                            </tr> 
                                            
                                        </tbody>
                                    </table>
                                  </div>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have. Nostrud tem exrcitation duis laboris nisi ut aliquip sed duis aute cupidata. There are many variations of passages of Lorem Ipsum available, but the majority have.</p>                                     */}
                                    
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-3 col-lg-3 col-sm-12">
                            <div className="card overflow-hidden" style={{height:"auto"}}>
                                <div className="text-center p-3 overlay-box ">
                                    <h3 className="mt-3 mb-1 text-white">Guest Information</h3>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Name</span> <strong className="text-black"> {guestInformationDetails.guest_fname??''}  {guestInformationDetails.guest_lname??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Email</span> <strong className="text-black"> {guestInformationDetails.guest_email??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Phone</span> <strong className="text-black"> {guestInformationDetails.guest_phone??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Gender</span> <strong className="text-black"> {guestInformationDetails.gender??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Nationality</span> <strong className="text-black"> {guestInformationDetails.guest_nationality??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Date Of Birth</span> <strong className="text-black"> {guestInformationDetails.guest_dob??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Birth Place</span> <strong className="text-black"> {guestInformationDetails.guest_birth_place??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">ID Card Number</span> <strong className="text-black"> {guestInformationDetails.guest_ID_card_number??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">ID Card Photo</span> <strong className="text-black"> <Link to={guestInformationDetails.guest_ID_card_photo} target="_blank"><img src={guestInformationDetails.guest_ID_card_photo??''} style={{width:"100px",height:"100px"}}/></Link> </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Address</span> <strong className="text-black"> {guestInformationDetails.guest_address??''} </strong>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className = "mb-0">Postcode</span> <strong className="text-black"> {guestInformationDetails.guest_postcode??''} </strong >
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className = "mb-0" > City </span> <strong className="text-black"> {guestInformationDetails.guest_city??''} </strong >
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" key={1}>
                                      <span className="mb-0">Country</span> <strong className="text-black"> {guestInformationDetails.guest_country??''} </strong>
                                    </li>
                                </ul>
                            </div>
                        </div>                      */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderDetails;