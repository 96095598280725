import React, { useState } from "react";
//import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import loginbg from "../../images/login_page_background_new.png";
import { Link, useNavigate } from "react-router-dom";
import { forgetPassword, resetPassword } from "../../services/AuthService";
import Swal from "sweetalert2";

const ResetPassword = ({ history }) => {
  const navigate = useNavigate();

  let errorsObj = {
    email: "",
    temp_password: "",
    password: "",
    confirm_password: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    email: "",
    temp_password: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("formData = ", formData);
    let error = true;
    const errorObj = { ...errorsObj };

    if (!formData.email.trim()) {
      errorObj.email = "Email is required";
      error = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errorObj.email = "Invalid email address";
      error = false;
    }

    if (!formData.temp_password.trim()) {
      errorObj.temp_password = "Temporary password is required";
      error = false;
    }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!formData.password.trim()) {
      errorObj.password = "Password is required";
      error = false;
    } else if (!passwordRegex.test(formData.password)) {
      errorObj.password =
        "Password must be at least 8 characters long with one uppercase and one lowercase letter";
      error = false;
    }
    if (!formData.confirm_password.trim()) {
      errorObj.confirm_password = "Confirm Password is required";
      error = false;
    } else if (formData.confirm_password !== formData.password) {
      errorObj.confirm_password = "Confirm Password is not same as password.";
      error = false;
    }
    setErrors(errorObj);
    if (!error) {
      return;
    }
    resetPassword(formData)
      .then((resp) => {
        console.log("resp = ", resp);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: resp.message,
          }).then(function () {
            navigate("/login");
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: error?.response?.data?.message,
        });
      });
  };
  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-4 pt-5">
            <img src={logo} alt="" width="auto" height={100} />
          </div>
          <h3 className="mb-2">Property Manager</h3>
        </div>
        <div
          className="aside-image"
          style={{ backgroundImage: "url(" + loginbg + ")" }}
        ></div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <form onSubmit={(e) => onSubmit(e)}>
                  <h3 className="text-center mb-4 text-black">
                    Reset Password
                  </h3>
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Email</strong>
                    </label>
                    <div>
                      <input
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="text-danger fs-12">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Temporary Password</strong>
                    </label>
                    <div>
                      <input
                        className="form-control"
                        name="temp_password"
                        value={formData.temp_password}
                        onChange={handleChange}
                      />
                      {errors.temp_password && (
                        <div className="text-danger fs-12">
                          {errors.temp_password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>New Password</strong>
                    </label>
                    <div>
                      <input
                        className="form-control"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <div className="text-danger fs-12">
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1">
                      <strong>Confirm Password</strong>
                    </label>
                    <div>
                      <input
                        className="form-control"
                        name="confirm_password"
                        value={formData.confirm_password}
                        onChange={handleChange}
                      />
                      {errors.confirm_password && (
                        <div className="text-danger fs-12">
                          {errors.confirm_password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <input
                      type="submit"
                      value="SUBMIT"
                      className="btn btn-primary btn-block"
                    />
                  </div>
                </form>
                <div className="new-account mt-3">
                  <div className="d-flex justify-content-between">
                    <Link className="text-primary" to="/forget-password">
                      Forget password?
                    </Link>
                    <div>
                      Don't have an account?{" "}
                      <Link className="text-primary" to="/register">
                        Sign up
                      </Link>
                    </div>
                    {/* <Link className="text-primary" to="/forget-password">
                      Forget password?
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
