import Swal from "sweetalert2";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";
import fetch from "../interceptor/fetchInterceptor";

export function signUp(postData) {
  return fetch({
    url: "/auth/register/property-manager",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function login(email, password, rememberMe) {
  const postData = {
    email,
    password,
    remember_me: rememberMe,
  };
  return fetch({
    url: "/auth/login/property-manager",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function forgetPassword(email) {
  const postData = {
    email,
  };
  return fetch({
    url: "/auth/forgotPassword/property-manager",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function resetPassword(formData) {
  const postData = {
    email: formData.email,
    temp_password: formData.temp_password,
    password: formData.password,
    confirm_password: formData.confirm_password,
  };
  return fetch({
    url: "/auth/changePassword/property-manager",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "SOMETHING_WENT_WRONG":
      //return 'Email already exists';
      // swal("Oops", "Email already exists", "error");
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Something went wrong!",
      });
      break;
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      // swal("Oops", "Email already exists", "error");
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Email already exists",
      });
      break;
    case "EMAIL_NOT_FOUND":
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Email not found",
      });
      //return 'Email not found';
      //swal("Oops", "Email not found", "error",{ button: "Try Again!",});
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      // swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Invalid email or password",
      });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}
