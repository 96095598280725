import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import { Dropdown, Modal, Button } from "react-bootstrap";
import services from "./services.json"; // Import your JSON data

import Select from "react-select";
import { Uploader } from "rsuite";
const ServiceList = () => {
  const { id } = useParams();
  const services_ = services.find((service) => service.id === parseInt(id));
  const [data, setData] = useState(
    document.querySelectorAll("#orderList tbody tr")
  );
  const sort = 10;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#orderList tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModal] = useState(false);

  const ThreeDot = () => {
    return (
      <>
        {" "}
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
            stroke="#3E4954"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    );
  };

  const Status = [
    {
      value: "1",
      label: "Active",
    },
    {
      value: "2",
      label: "Deactive",
    },
  ];
  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Sub Services</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/main-services">My Services</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">{services_.name}</Link>
            </li>
          </ol>
        </div>
        <Link
          to="#"
          className="btn btn-primary rounded light"
          onClick={() => setAddModalOpen(true)}
        >
          Add Sub Services
        </Link>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display table-hover display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting">No.</th>
                    <th className="sorting">Sub Service</th>
                    <th className="sorting">Service Title</th>
                    <th className="sorting">Description</th>
                    <th className="sorting">Price</th>
                    <th className="sorting">Status</th>
                    <th className="sorting">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {services_.sub_services.map((item) => (
                    <tr role="row" className="odd" key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.sub_service}</td>
                      <td>{item.service_title}</td>
                      <td>{item.desc}</td>
                      <td>€{item.price}</td>
                      <td>
                        {item.status == 1 ? (
                          <span className="text-primary">Active</span>
                        ) : (
                          <span className="text-danger">Deactive</span>
                        )}
                      </td>
                      <td>
                        <Dropdown className="ms-auto">
                          <Dropdown.Toggle
                            variant=""
                            className="btn-link i-false"
                          >
                            <ThreeDot />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => setEditModalOpen(true)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => setConfirmModal(true)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="#"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" />
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="#"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="#"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add service modal */}
      {/* <Modal className="fade" show={addModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Add Sub Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddModalOpen(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12">
                <label className="form-label">Service Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Taxi"
                  required=""
                />
              </div>
              <div className="mb-12 col-lg-12 col-md-12">
                <label className="form-label">Status</label>
                <Select
                  options={Status}
                  defaultValue={Status[0]}
                  className="custom-react-select"
                  isSearchable={false}
                />
              </div>
              <div className="mb-12 col-lg-12 col-md-12">
                <label className="form-label">Price</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="$20"
                  required=""
                />
              </div>

              <div className="dropzone-admin mb-3">
                <label className="form-label">Image</label>
                <Uploader
                  className="dropzone"
                  action="//jsonplaceholder.typicode.com/posts/"
                  draggable
                >
                  <div className="dz-message needsclick">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <h6>Drop files here or click to upload.</h6>
                  </div>
                </Uploader>
              </div>

              <div className="mb-3 col-12">
                <label className="form-label">Tags</label>
                <textarea
                  className="form-control"
                  defaultValue={"City, Airport"}
                  rows="4"
                />
              </div>
              <div className="mb-3 col-12">
                <label className="form-label">Description</label>
                <textarea className="form-control" defaultValue={""} rows="4" />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setAddModalOpen(false)} variant="primary">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => setAddModalOpen(false)}> Add </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal className="fade" show={addModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Add Sub Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAddModalOpen(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Sub Service</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Taxi"
                  required=""
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Service Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Patel Taxi Services"
                  required=""
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Status</label>
                <Select
                  options={Status}
                  defaultValue={Status[0]}
                  className="custom-react-select"
                  isSearchable={false}
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Price</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="$20"
                  required=""
                />
              </div>

              <div className="dropzone-admin mb-3">
                <label className="form-label">Image</label>
                <Uploader
                  className="dropzone"
                  action="//jsonplaceholder.typicode.com/posts/"
                  draggable
                >
                  <div className="dz-message needsclick">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <h6>Drop files here or click to upload.</h6>
                  </div>
                </Uploader>
              </div>
              <div className="mb-3 col-12">
                <label className="form-label">Description</label>
                <textarea className="form-control" rows="4" />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setAddModalOpen(false)} variant="primary">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => setAddModalOpen(false)}> Add </Button>
        </Modal.Footer>
      </Modal>

      {/* edit service modal */}
      <Modal className="fade" show={editModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Edit Sub Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEditModalOpen(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Sub Service</label>
                <input
                  type="text"
                  className="form-control"
                  value="Taxi"
                  placeholder="Taxi"
                  required=""
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Service Title</label>
                <input
                  type="text"
                  className="form-control"
                  value="Patel Taxi Services"
                  placeholder="Patel Taxi Services"
                  required=""
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Status</label>
                <Select
                  options={Status}
                  defaultValue={Status[0]}
                  className="custom-react-select"
                  isSearchable={false}
                />
              </div>

              <div className="mb-12 col-lg-12 col-md-12  mb-2">
                <label className="form-label">Price</label>
                <input
                  type="text"
                  className="form-control"
                  value="20"
                  placeholder="$20"
                  required=""
                />
              </div>
              <div className="dropzone-admin mb-3">
                <label className="form-label">Image</label>
                <Uploader
                  className="dropzone"
                  action="//jsonplaceholder.typicode.com/posts/"
                  draggable
                >
                  <div className="dz-message needsclick">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <h6>Drop files here or click to upload.</h6>
                  </div>
                </Uploader>
              </div>
              <div className="mb-3 col-12">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  defaultValue={"Lorem ipsum dolor set amet"}
                  rows="4"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setEditModalOpen(false)} variant="primary">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => setEditModalOpen(false)}> Update </Button>
        </Modal.Footer>
      </Modal>

      {/* delete service modal */}
      <Modal className="fade" show={confirmModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Sub Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setConfirmModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to delete this service ? </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setConfirmModal(false)} variant="primary">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => setConfirmModal(false)}> Yes </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ServiceList;
