import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import PmService from "../../../services/PmService";
import Loader from "../Loader";


const MainService = () => {
  const [serviceList, setServiceList] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    PmService.GetMainServiceList()
      .then((resp) => {
        if (resp.success) {
          setServiceList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error=-=-=-=-=-=-=-=-=-=-=-", error);
      })
      .finally(function () {
        setLoader(false);
      });
  }, []);

  return loader ? <Loader /> : (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Main Services</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/main-services">Main Services List</Link>
            </li>
          </ol>
        </div>
      </div>
      <Tab.Container defaultActiveKey={"List"}>
        <Tab.Content>
          <Tab.Pane eventKey={"List"}>
            <div className="row">
              {serviceList.map((item, ind) => (
                <div
                  className="col-xl-3 col-xxl-3 col-md-4 col-sm-4 col-lg-4 m-b30"
                  key={ind}
                >
                  <Link to={`/sub-services/${btoa(item.service)}/${btoa(item.id)}`}>
                    {/* <Link to="#"> */}
                    <div className="property-card style-1 ">
                      <div className="dz-media ">
                        <img
                          className="fix-height-250"
                          src={item.image}
                          alt="/"
                        />
                      </div>
                      <div
                        className="dz-content"
                        style={{ paddingBottom: "5px" }}
                      >
                        <div className="dz-footer">
                          <div className="dz-media d-flex align-items-center">
                            <img
                              className="fix-height-30"
                              src={item?.icon}
                              alt="icon"
                            />
                            <h5 className="title mb-0" style={{ marginLeft: "10px" }}>{item.service}</h5>
                          </div>
                        </div>
                        <div className="dz-meta">
                          <span className="badge badge-primary">
                            {" "}
                            {item.total_sub_services} services
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default MainService;
