import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import PmService from "../../../services/PmService";
import Swal from "sweetalert2";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";

const SubService = () => {
    const [subServiceLoader, setSubServiceLoader] = useState(false);
    const { id, name } = useParams();
    const serviceId = atob(id);
    const serviceName = atob(name);
    const [loader, setLoader] = useState(false);
    const [subServices, setSubServices] = useState([]);
    const [subServiceId, setSubServiceId] = useState(null);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [confirmModalOpen, setConfirmModal] = useState(false);
    const [image, setImage] = useState(null);
    const [sercieCatList, setServiceCatList] = useState([]);
    const [formData, setFormData] = useState({
        role: '',
        service_id: serviceId,
        sub_service: "",
        service_title: "",
        desc: "",
        price: "",
        pm_price: "",
        status: {
            value: "1",
            label: "Active",
        }
    });
    const [subServiceCategoryId, setSubServiceCategoryId] = useState({
        value: "",
        label: "",
    });

    const [statusOption, setStatusOption] = useState("all");

    const tooltip = (
        <Tooltip id="tooltip">
            Added by admin
        </Tooltip>
    );

    const [availableAt, setAvailableAt] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(subServices);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);

        const list = reorderedItems.map((item, index) => ({
            sub_service_id: item.id,
            order: index
        }));

        changeSubServicesOrder(list);
        // fetchSubServiceList(statusOption);
    };

    const changeSubServicesOrder = (list) => {
        PmService.ReorderSubServices(serviceId, list)
        .then((resp) => {
            fetchSubServiceList(statusOption);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const errorsObj = {
        sub_service: "",
        service_title: "",
        desc: "",
        price: "",
        pm_price: "",
        image: "",
        available_at: ""
    };
    const [errors, setErrors] = useState(errorsObj);

    const Status = [
        {
            value: "1",
            label: "Active",
        },
        {
            value: "0",
            label: "Inactive",
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'price' || name == 'pm_price') {
            if (!/^\d*\.?\d*$/.test(value)) {
                return;
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setErrors({
            ...errors,
            [name]: "",
        });
    }

    const handleStatusChange = (e, name) => {
        const { value, label } = e;
        setFormData((prevData) => ({
            ...prevData,
            [name]: { value, label }
        }))
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            setErrors({
                ...errors,
                image: "",
            });
        }
    };

    const handleDeleteSubService = (e) => {
        e.preventDefault();
        if (subServiceId != null) {
            PmService.DeleteSubService(subServiceId)
                .then((resp) => {
                    setConfirmModal(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Service has been deleted successfully.",
                    }).finally(function () {
                        window.location.reload();
                    });
                })
                .catch((error) => {
                    console.log("error in delete service = ", error);
                });
        }
    };

    const handleAddSubService = () => {
        console.log("availableAt=  ", availableAt)
        let error = true;
        const errorObj = { ...errorsObj };

        if (subServiceCategoryId.value == "") {
            errorObj.sub_service = "Service category is required";
            error = false;
        }
        if (formData.service_title.trim() === "") {
            errorObj.service_title = "Service Title is required";
            error = false;
        }
        if (formData.desc.trim() === "") {
            errorObj.desc = "Service Description is required";
            error = false;
        }
        if (formData.price.trim() === "") {
            errorObj.price = "Service Price is required";
            error = false;
        }
        if (image === null) {
            errorObj.image = "Service Image is required";
            error = false;
        }
        if (availableAt.length <= 0) {
            errorObj.available_at = "Please select atleast one option."
            error = false;
        }

        setErrors(errorObj);
        if (!error) {
            return;
        }
        const passData = {
            service_id: formData.service_id,
            service_category_id: subServiceCategoryId?.value,
            service_title: formData.service_title,
            desc: formData.desc,
            price: formData.price,
            status: formData.status.value,
            image: image,
            available_at: availableAt.toString()
        }
        setLoader(true);
        PmService.AddSubService(passData)
            .then((resp) => {
                console.log("resp = ", resp);
                if (resp.success) {
                    setAddModalOpen(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Service has been added successfully.",
                    }).finally(function () {
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                console.log("error in add service = ", error);
                Swal.fire({
                    icon: "error",
                    title: "Oops!",
                    text: "Something went wrong! Please try again later.",
                });
            })
            .finally(function () {
                setLoader(false);
            })
    }

    const handleEditSubService = () => {
        console.log("availableAt=  ", availableAt)
        let error = true;
        const errorObj = { ...errorsObj };

        if (subServiceCategoryId.value == "") {
            errorObj.sub_service = "Service category is required";
            error = false;
        }
        if (formData.service_title.trim() === "") {
            errorObj.service_title = "Service Title is required";
            error = false;
        }
        if (formData.desc.trim() === "") {
            errorObj.desc = "Service Description is required";
            error = false;
        }
        if (formData.price.trim() === "") {
            errorObj.price = "Service Price is required";
            error = false;
        }
        if (formData.role == 2 && formData.pm_price.trim() === "") {
            errorObj.pm_price = "Price should not empty.";
            error = false;
        }
        if (availableAt.length <= 0) {
            errorObj.available_at = "Please select atleast one option.";
            error = false;
        }

        setErrors(errorObj);
        if (!error) {
            return;
        }
        const passData = {
            service_id: formData.service_id,
            service_category_id: subServiceCategoryId?.value,
            service_title: formData.service_title,
            desc: formData.desc,
            price: formData.role == 2 ? formData.pm_price : formData.price,
            status: formData.status.value,
            available_at: availableAt.toString()
        }
        if (image != null) {
            passData.image = image
        }
        // console.log("formData = ", formData);
        console.log("passData = ", passData);
        setLoader(true);
        PmService.UpdateSubService(passData, subServiceId)
            .then((resp) => {
                console.log("resp = ", resp);
                if (resp.success) {
                    setShowEditModal(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Service has been updated successfully.",
                    }).finally(function () {
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                console.log("error in add service = ", error);
                Swal.fire({
                    icon: "error",
                    title: "Oops!",
                    text: "Something went wrong! Please try again later.",
                }).finally(function () {
                    setAddModalOpen(false);
                });
            })
            .finally(function () {
                setLoader(false);
            })
    }

    const clearFeilds = () => {
        setFormData({
            role: "",
            service_id: serviceId,
            service_title: "",
            desc: "",
            price: "",
            pm_price: "",
            status: {
                value: "1",
                label: "Active",
            }
        });
        setErrors({});
        setSubServiceCategoryId({
            value: "",
            label: "",
        });
        setImage(null);
        setAvailableAt([]);
        setSelectAll(false);
    }

    const setFeilds = (item) => {
        console.log("item= ", item)
        setFormData({
            role: item.role,
            service_id: serviceId,
            service_title: item.service_title ?? "",
            desc: item.desc_ ?? "",
            price: item.price ?? "",
            pm_price: item.pm_price ?? item.price,
            status: {
                value: item.status,
                label: item.status == 1 ? "Active" : "Inactive",
            }
        })
        setSubServiceCategoryId({
            value: item?.service_category_id,
            label: item?.service_category,
        });
        setAvailableAt(item?.available_at);
        if (item?.available_at.length == 3) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }

    const fetchServiceCategoryList = async (serviceIdFilterValue, statusFilterValue) => {
        // setLoader(true);
        setServiceCatList([]);
        await PmService.GetAllServicesCategory(serviceIdFilterValue, statusFilterValue)
            .then((resp) => {
                console.log("resp", resp)
                if (resp.success) {
                    const options = resp?.data?.list.map(item => ({
                        value: item.id,
                        label: item.category
                    }));
                    setServiceCatList(options);
                }
            })
            .catch((error) => {
                console.log("error in fetch service category list", error);
            })
            .finally(function () {
                // setLoader(false);
            });
    }

    const fetchSubServiceList = (status) => {
        PmService.GetSubServices(serviceId, status)
            .then((resp) => {
                console.log("response =  ", resp);
                if (resp.success) {
                    setSubServices(resp.data.list);
                }
            })
            .catch((error) => {
                console.log("error =  ", error);
            })
            .finally(function () {
                setLoader(false);
                setSubServiceLoader(false);
            })
    }

    const handleFilter = (e) => {
        setSubServices([]);
        setSubServiceLoader(true);
        console.log("-=-=-", e.target.value);
        setStatusOption(e.target.value);
        fetchSubServiceList(e.target.value);
    }

    const handleAvailableAtChange = (e) => {
        setErrors({
            ...errors,
            available_at: "",
        });
        const { value, checked } = e.target;
        if (value === "For All") {
            setSelectAll(checked);
            setAvailableAt(checked ? ["check_in", "check_out", "while_stay"] : []);
        } else {
            if (checked) {
                const newAvailableAt = [...availableAt, value];
                setAvailableAt(newAvailableAt);
                if (newAvailableAt.length === 3) {
                    setSelectAll(true);
                }
            } else {
                const newAvailableAt = availableAt.filter((item) => item !== value);
                setAvailableAt(newAvailableAt);
                setSelectAll(false);
            }
        }
    };

    useEffect(() => {
        setLoader(true);
        fetchSubServiceList('all');
        fetchServiceCategoryList(serviceId, '1');
    }, [])
    return loader ? <Loader /> : (
        <>
            <div className="form-head page-titles d-flex  align-items-center">
                <div className="me-auto  d-lg-block">
                    <h4 className="mb-1">Services</h4>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <Link to="/main-services">Main Services</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="#">{serviceName}</Link>
                        </li>
                    </ol>
                </div>
                <select
                    name="cars"
                    className="form-select form-control-lg rounded"
                    value={statusOption}
                    style={{
                        cursor: "pointer",
                        width: "fit-content",
                        fontSize: "14px",
                        backgroundColor: "var(--primary)",
                        color: "white",
                        marginRight: "10px"
                    }}
                    onChange={(e) => handleFilter(e)}
                >
                    <option value="all">All</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>
                <Link
                    to="#"
                    className="btn btn-primary rounded light"
                    onClick={() => { clearFeilds(); setAddModalOpen(true); }}
                >
                    Add Services
                </Link>
            </div>

            <div className="col-xl-12">
                <div className="table-responsive table-hover fs-14 pm-list-table">
                    <div id="orderList" className="dataTables_wrapper no-footer">
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <table
                                className="table display table-hover display dataTablesCard card-table dataTable no-footer"
                                id="example5"
                            >
                                <thead>
                                    <tr role="row">
                                        <th>No</th>
                                        <th>Image</th>
                                        <th>Main Service</th>
                                        <th>Category</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Available At</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <StrictModeDroppable droppableId="droppable-rows">
                                    {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                            {subServices.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`${item.role === 2 ? "bg-admin" : ""} ${
                                                                snapshot.isDragging ? "dragging" : ""
                                                            }`}
                                                            role="row"
                                                        >
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <img className="fix-height-50 rounded-circle" src={item.image} alt="icon" />
                                                            </td>
                                                            <td>{item?.service || "-"}</td>
                                                            <td>{item?.service_category || "-"}</td>
                                                            <td>{item?.service_title || "-"}</td>
                                                            <td className="text-truncate">
                                                                {item?.desc_ ? (item?.desc_.length > 25 ? `${item?.desc_.substring(0, 25)}...` : item?.desc_) : "-"}
                                                            </td>
                                                            <td>
                                                                {item?.available_at?.includes("check_in") && (
                                                                    <span className="badge badge-info me-1">Check In</span>
                                                                )}
                                                                {item?.available_at?.includes("while_stay") && (
                                                                    <span className="badge bg-light text-dark me-1">While Stay</span>
                                                                )}
                                                                {item?.available_at?.includes("check_out") && (
                                                                    <span className="badge bg-dark">Check Out</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item?.role === 2
                                                                    ? item?.pm_price
                                                                        ? "€" + item?.pm_price
                                                                        : item?.price
                                                                        ? "€" + item?.price
                                                                        : "-"
                                                                    : item?.price
                                                                    ? "€" + item?.price
                                                                    : "-"}
                                                            </td>
                                                            <td>
                                                                {item?.status === 1 ? (
                                                                    <span className="badge badge-success">Active</span>
                                                                ) : (
                                                                    <span className="badge badge-danger">Inactive</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    onClick={() => {
                                                                        setShowEditModal(true);
                                                                        setFeilds(item);
                                                                        setSubServiceId(item.id);
                                                                    }}
                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                >
                                                                    <i className="fa fa-pencil"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </StrictModeDroppable>
                            </table>
                        </DragDropContext>
                    </div>
                </div >
            </div >

            {/* Add service modal */}
            < Modal className="fade" show={addModalOpen} onHide={() => { clearFeilds(); setAddModalOpen(false) }} centered >
                <Modal.Header>
                    <Modal.Title>Add Service</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => { clearFeilds(); setAddModalOpen(false) }}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            {/* <div cla  */}

                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Service Category</label>
                                <div>
                                    <Select
                                        options={sercieCatList}
                                        noOptionsMessage={() => "Please contact admin"}
                                        className="custom-react-select"
                                        isSearchable={false}
                                        onChange={(e) => {
                                            setSubServiceCategoryId(e);
                                            setErrors({
                                                ...errors,
                                                sub_service: "",
                                            });
                                        }}
                                    />
                                    {errors.sub_service && (
                                        <div className="text-danger fs-12">
                                            {errors.sub_service}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Service Title</label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Service Title"
                                        name="service_title"
                                        value={formData.service_title}
                                        onChange={handleChange}
                                    />
                                    {errors.service_title && (
                                        <div className="text-danger fs-12">
                                            {errors.service_title}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">Status</label>
                                <Select
                                    options={Status}
                                    className="custom-react-select"
                                    name="status"
                                    value={formData.status}
                                    onChange={(e) => handleStatusChange(e, "status")}
                                />
                            </div>

                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">Price</label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Service Price"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                    />
                                    {errors.price && (
                                        <div className="text-danger fs-12">
                                            {errors.price}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Image</label>
                                <div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        onChange={handleFileChange}
                                    />
                                    {errors.image && (
                                        <div className="text-danger fs-12">
                                            {errors.image}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-3 col-12">
                                <label className="form-label">Description</label>
                                <div>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder="Enter Service Description"
                                        name="desc"
                                        value={formData.desc}
                                        onChange={handleChange}
                                    />
                                    {errors.desc && (
                                        <div className="text-danger fs-12">
                                            {errors.desc}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-3 col-12">
                                <Form.Group>
                                    <Form.Label>Available at</Form.Label>
                                    <div className="d-flex w-full justify-content-between">
                                        <Form.Check
                                            type="checkbox"
                                            label="For All"
                                            value="For All"
                                            id="forAll"
                                            checked={selectAll}
                                            onChange={handleAvailableAtChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Check In"
                                            value="check_in"
                                            id="checkin"
                                            checked={availableAt?.includes("check_in")}
                                            onChange={handleAvailableAtChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Check Out"
                                            value="check_out"
                                            id="checkout"
                                            checked={availableAt?.includes("check_out")}
                                            onChange={handleAvailableAtChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="While Stay"
                                            value="while_stay"
                                            id="whileStay"
                                            checked={availableAt?.includes("while_stay")}
                                            onChange={handleAvailableAtChange}
                                        />
                                    </div>
                                    {errors.available_at && (
                                        <div className="text-danger fs-12">
                                            {errors.available_at}
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { clearFeilds(); setAddModalOpen(false) }} variant="danger">
                        {" "}
                        Close{" "}
                    </Button>
                    <Button onClick={(e) => { e.preventDefault(); handleAddSubService() }} variant="success"> Add </Button>
                </Modal.Footer>
            </Modal >

            {/* Edit service modal */}
            < Modal className="fade" show={showEditModal} onHide={() => { clearFeilds(); setShowEditModal(false) }} centered >
                {/* <Modal.Header> */}
                {/* </Modal.Header> */}
                <Modal.Header>
                    <Modal.Title>Edit Service</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => { clearFeilds(); setShowEditModal(false) }}
                    ></Button>
                </Modal.Header>
                {formData.role == 2 && (<p className="text-primary mt-2 mb-0 d-flex justify-content-center align-items-center">
                    Service has been added by admin, you can only change the price.
                </p>)}
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Service Category</label>
                                <div>
                                    <Select
                                        options={sercieCatList}
                                        value={subServiceCategoryId}
                                        noOptionsMessage={() => "Please contact admin"}
                                        className="custom-react-select"
                                        isSearchable={false}
                                        onChange={(e) => {
                                            setSubServiceCategoryId(e);
                                            setErrors({
                                                ...errors,
                                                sub_service: "",
                                            });
                                        }}
                                        isDisabled={formData.role == 2}
                                    />
                                    {errors.sub_service && (
                                        <div className="text-danger fs-12">
                                            {errors.sub_service}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Service Title</label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Service Title"
                                        name="service_title"
                                        value={formData.service_title}
                                        onChange={handleChange}
                                        disabled={formData.role == 2}
                                    />
                                    {errors.service_title && (
                                        <div className="text-danger fs-12">
                                            {errors.service_title}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">Status</label>
                                <Select
                                    options={Status}
                                    className="custom-react-select"
                                    name="status"
                                    value={formData.status}
                                    onChange={(e) => handleStatusChange(e, "status")}
                                    isDisabled={formData.role == 2}
                                />
                            </div>

                            <div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">{formData.role == 2 ? 'Admin Price' : 'Price'}</label>
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Service Price"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                        disabled={formData.role == 2}
                                    />
                                    {errors.price && (
                                        <div className="text-danger fs-12">
                                            {errors.price}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {formData.role == 2 && (<div className="mb-12 col-lg-12 col-md-12  mb-2">
                                <label className="form-label">My Price</label>
                                <div>
                                    <input
                                        type="text"
                                        className={`form-control ${formData.role == 2 ? 'border border-2 border-primary' : ''}`}
                                        placeholder="Enter Service Price"
                                        name="pm_price"
                                        value={formData.pm_price}
                                        onChange={handleChange}
                                    />
                                    {errors.pm_price && (
                                        <div className="text-danger fs-12">
                                            {errors.pm_price}
                                        </div>
                                    )}
                                </div>
                            </div>)}

                            <div className="mb-12 col-lg-12 col-md-12 mb-2">
                                <label className="form-label">Image</label>
                                <div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        name="image"
                                        onChange={handleFileChange}
                                        disabled={formData.role == 2}
                                    />
                                    {errors.image && (
                                        <div className="text-danger fs-12">
                                            {errors.image}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-3 col-12">
                                <label className="form-label">Description</label>
                                <div>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder="Enter Service Description"
                                        name="desc"
                                        value={formData.desc}
                                        onChange={handleChange}
                                        disabled={formData.role == 2}
                                    />
                                    {errors.desc && (
                                        <div className="text-danger fs-12">
                                            {errors.desc}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-3 col-12">
                                <Form.Group>
                                    <Form.Label>Available at</Form.Label>
                                    <div className="d-flex w-full justify-content-between">
                                        <Form.Check
                                            type="checkbox"
                                            label="For All"
                                            value="For All"
                                            id="forAll"
                                            checked={selectAll}
                                            onChange={handleAvailableAtChange}
                                            disabled={formData.role == 2}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Check In"
                                            value="check_in"
                                            id="checkin"
                                            checked={availableAt?.includes("check_in")}
                                            onChange={handleAvailableAtChange}
                                            disabled={formData.role == 2}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Check Out"
                                            value="check_out"
                                            id="checkout"
                                            checked={availableAt?.includes("check_out")}
                                            onChange={handleAvailableAtChange}
                                            disabled={formData.role == 2}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="While Stay"
                                            value="while_stay"
                                            id="whileStay"
                                            checked={availableAt?.includes("while_stay")}
                                            onChange={handleAvailableAtChange}
                                            disabled={formData.role == 2}
                                        />
                                    </div>
                                    {errors.available_at && (
                                        <div className="text-danger fs-12">
                                            {errors.available_at}
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { clearFeilds(); setShowEditModal(false) }} variant="danger">
                        {" "}
                        Close{" "}
                    </Button>
                    <Button onClick={(e) => { e.preventDefault(); handleEditSubService() }} variant="success"> Save </Button>
                </Modal.Footer>
            </Modal >

            {/* Delete service modal */}
            < Modal
                className="fade"
                show={confirmModalOpen}
                onHide={() => setConfirmModal(false)}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Service</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setConfirmModal(false)}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to delete this service ? </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setConfirmModal(false)} variant="danger">
                        {" "}
                        Close{" "}
                    </Button>
                    <Button onClick={handleDeleteSubService} variant="success"> Yes </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default SubService;
