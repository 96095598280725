import React, { useState } from "react";
//import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import loginbg from "../../images/login_page_background_new.png";
import { Link, useNavigate } from "react-router-dom";
import { forgetPassword } from "../../services/AuthService";
import Swal from "sweetalert2";

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("email = = ", email);
    forgetPassword(email)
      .then((resp) => {
        console.log("resp = ", resp);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: resp.message,
          }).then(function () {
            navigate("/reset-password");
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: error?.response?.data?.message,
        });
      });
  };
  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-4 pt-5">
            <img src={logo} alt="" width="auto" height={100} />
          </div>
          <h3 className="mb-2">Property Manager</h3>
        </div>
        <div
          className="aside-image"
          style={{ backgroundImage: "url(" + loginbg + ")" }}
        ></div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <form onSubmit={(e) => onSubmit(e)}>
                  <h3 className="text-center mb-4 text-black">
                    Forget Password
                  </h3>
                  <div className="form-group">
                    <label>
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="text-center">
                    <input
                      type="submit"
                      value="SUBMIT"
                      className="btn btn-primary btn-block"
                    />
                  </div>
                </form>
                <div className="new-account mt-3">
                  <div className="d-flex justify-content-between">
                    <Link className="text-primary" to="/reset-password">
                      Reset password?
                    </Link>
                    <div>
                      Don't have an account?{" "}
                      <Link className="text-primary" to="/register">
                        Sign up
                      </Link>
                    </div>
                    {/* <Link className="text-primary" to="/forget-password">
                      Forget password?
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
