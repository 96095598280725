import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Button, Modal, ProgressBar } from "react-bootstrap";
import "rsuite/dist/rsuite.min.css";

import ModalVideo from "react-modal-video";
import "../../../../node_modules/react-modal-video/scss/modal-video.scss";

//images
import property1 from "../../../images/property/1.jpg";
import property2 from "../../../images/property/2.jpg";
import property3 from "../../../images/property/3.jpg";
import avat1 from "../../../images/avatar/1.jpg";
import avat2 from "../../../images/avatar/2.jpeg";
import avat3 from "../../../images/avatar/3.jpeg";
import avat4 from "../../../images/avatar/4.jpeg";
import DemoSlider from "./DemoSlider";
import PmService from "../../../services/PmService";
import Swal from "sweetalert2";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";

const cardData = [
  {
    image: property1,
    property: "Sunnyvale Villa",
    tag: "London",
    price: "$22000",
    services: "3",
    bath: "2",
    area: "2000",
    name: "Thomas Djons",
    option: "image",
    profile: avat1,
  },
  {
    image: property2,
    property: "Mountain Retreat",
    tag: "Paris",
    price: "$45000",
    services: "5",
    bath: "3",
    area: "5000",
    name: "Oliver Jean",
    option: "image",
    profile: avat4,
  },
  {
    image: property3,
    property: "Urban Loft",
    tag: "Dubai",
    price: "$32000",
    services: "4",
    bath: "3",
    area: "3000",
    name: "Jane Cooper",
    option: "video",
    profile: avat3,
  },
  {
    image: property1,
    property: "Beachfront Paradise",
    tag: "London",
    price: "$52000",
    services: "6",
    bath: "5",
    area: "5500",
    name: "Monalisa",
    option: "image",
    profile: avat2,
  },
  {
    image: property2,
    property: "Countryside Estate",
    tag: "London",
    price: "$22000",
    services: "3",
    bath: "2",
    area: "2000",
    name: "Thomas Djons",
    option: "image",
    profile: avat1,
  },
  {
    image: property3,
    property: "Lakeview Cottage",
    tag: "France",
    price: "$25000",
    services: "3",
    bath: "3",
    area: "2500",
    name: "Keanu Repes",
    option: "video",
    profile: avat3,
  },
  {
    image: property1,
    property: "Lakeside Haven",
    tag: "London",
    price: "$52000",
    services: "6",
    bath: "5",
    area: "5500",
    name: "Monalisa",
    option: "image",
    profile: avat2,
  },
  {
    image: property2,
    property: "Hilltop Hideaway",
    tag: "Russia",
    price: "$30000",
    services: "4",
    bath: "3",
    area: "4000",
    name: "Rio Fernan",
    option: "image",
    profile: avat4,
  },
];

const PropertyList = () => {
  const [loader, setLoader] = useState(false);
  const [propertyLoader, setPropertyLoader] = useState(false);
  const [addPropertyModal, setAddPropertyModal] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [statusOption, setStatusOption] = useState("all");
  const [formData, setFormData] = useState({
    bookingIcalLink: "",
    airbnbIcalLink: "",
    airbnbPropertyLink: "",
    pdfLink: ""
  });

  const errorsObj = {
    airbnbIcalLink: "",
    airbnbPropertyLink: "",
    pdfLink: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [propertyList, setPropertyList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const isValidUrl = (url) => {
    const urlPattern = /^https:\/\/([a-z]+\.)?.*$/;
    return !!urlPattern.test(url);
  };

  const isValidPdfUrl = (url) => {
    const urlPattern = /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(:\d{1,5})?(\/.*)?\.pdf(\?.*)?$/i;
    return !!urlPattern.test(url);
  };

  const isValidPdfOrDriveUrl = (url) => {
    const pdfUrlPattern = /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[a-z]{2,}(:\d{1,5})?(\/.*)?\.pdf(\?.*)?$/i;
    const driveLinkPattern = /https:\/\/drive\.google\.com\/(?:file\/d\/|open\?id=|uc\?id=)([a-zA-Z0-9_-]+)(?:\/view\?[^#]+)?/;
    return pdfUrlPattern.test(url) || driveLinkPattern.test(url);
  };

  


  const handleAddProperty = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    if (!formData.airbnbIcalLink.trim()) {
      errorObj.airbnbIcalLink = "Airbnb iCal link is required.";
      error = true;
    } else if (!isValidUrl(formData.airbnbIcalLink.trim())) {
      errorObj.airbnbIcalLink = "Airbnb iCal link is not a valid URL.";
      error = true;
    }

    if (!formData.airbnbPropertyLink.trim()) {
      errorObj.airbnbPropertyLink = "Airbnb property link is required.";
      error = true;
    } else if (!isValidUrl(formData.airbnbPropertyLink.trim())) {
      errorObj.airbnbPropertyLink = "Airbnb property link is not a valid URL.";
      error = true;
    }

    if (
      formData.bookingIcalLink &&
      !isValidUrl(formData.bookingIcalLink.trim())
    ) {
      errorObj.bookingIcalLink = "Booking iCal link is not a valid URL.";
      error = true;
    }
    if (
      formData.pdfLink &&
      !isValidPdfOrDriveUrl(formData.pdfLink.trim())
    ) {
      errorObj.pdfLink = "E-PDF link is not a valid URL.";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoading(true);

    const passData = {
      airbnb_url: formData.airbnbIcalLink,
      property_detail_sync_url: formData.airbnbPropertyLink,
    };
    if (formData.bookingIcalLink) {
      passData.booking_url = formData.bookingIcalLink;
    }
    if (formData.pdfLink) {
      passData.pdf_link = formData.pdfLink;
    }

    let progressValue = 0;
    const increment = () => {
      progressValue = Math.min(
        progressValue + Math.floor(Math.random() * (8 - 0 + 1) + 0),
        95
      ); // Increase progress by 5% at a time
      setProgress(progressValue);
    };

    const interval = setInterval(increment, 1000); // Update progress every second

    try {
      PmService.AddProperty(passData)
        .then((resp) => {
          if (resp) {
            clearInterval(interval); // Clear the interval
            setProgress(100); // Set progress to 100%
            setTimeout(() => {
              setLoading(false);
              setProgress(0);
              setAddPropertyModal(false);
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Property details fetched successfully.",
              }).finally(function () {
                window.location.reload();
              });
            }, 500);
          }
        })
        .catch((error) => {
          setLoading(false);
          setProgress(0);
          setAddPropertyModal(false);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: "Something went wrong. Please try again after sometime.",
          })
        });

      // If the API call succeeds

      // Reset loading state and progress
    } catch (error) {
      console.error("Error during API call", error);
      setLoading(false);
      setProgress(0);
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        console.log("error", error);
      }
    }
  };

  const handleFilter = (e) => {
    setPropertyList([]);
    setPropertyLoader(true);
    console.log("-=-=-", e.target.value);
    setStatusOption(e.target.value);
    fetchPropertyList(e.target.value);
  }

  const fetchPropertyList = (option) => {
    PmService.GetPropertyList(option)
      .then((resp) => {
        if (resp.success) {
          setPropertyList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error=", error);
      })
      .finally(() => {
        setLoader(false);
        setPropertyLoader(false);
      });
  }

  useEffect(() => {
    setLoader(true);
    fetchPropertyList('all');
  }, []);

  return loader ? <Loader /> : (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Property </h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/property-list">Property List</Link>
            </li>
          </ol>
        </div>
        <select
          name="cars"
          className="form-select form-control-lg rounded"
          value={statusOption}
          style={{
            cursor: "pointer",
            width: "fit-content",
            fontSize: "14px",
            backgroundColor: "var(--primary)",
            color: "white",
            marginRight: "10px"
          }}
          onChange={(e) => handleFilter(e)}
        >
          <option value="all">All</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        <Button
          className="btn btn-primary btn-sm rounded light"
          onClick={() => {
            setAddPropertyModal(true);
            setFormData((prevFormData) => ({
              ...prevFormData,
              airbnbIcalLink: "",
              airbnbPropertyLink: "",
              bookingIcalLink: "",
            }));
            setErrors(errorsObj);
          }}
        >
          Add Property{" "}
        </Button>
      </div>

      <Tab.Container defaultActiveKey={"Grid"}>
        {/* <div className="my-3 row">
          <div className="col-sm-6 align-self-center">
            <p className="mb-0 d-sm-block d-none">Showing 1-9 of 13 Results</p>
          </div>
        </div> */}
        <Tab.Content>
          <Tab.Pane eventKey={"Grid"}>
            <div className="row">
              {
                propertyLoader ? (
                  <div><ModalLoader /></div>
                ) :
                  propertyList.length > 0 ? (
                    propertyList.map((item, ind) => (
                      <div
                        className="col-xl-3 col-xxl-4 col-md-6 col-sm-6 col-lg-4 m-b30"
                        key={ind}
                      >
                        <Link to={`./../property-details/${btoa(item.id)}`}>
                          <div className="property-card style-1">
                            {item.option === "slider" ? (
                              <DemoSlider />
                            ) : (
                              <div className="dz-media">
                                <ul>
                                  <li class={`badge badge-sm badge-${item.status == 1 ? 'success' : 'danger'} light`}>
                                    {item.status == 1 ? 'Active' : 'Inactive'}
                                  </li>
                                    
                                </ul>
                                <img
                                  src={item?.photo[0]?.url}
                                  alt="image"
                                  className="fix-height-250"
                                />
                              </div>
                            )}
                            <div
                              className="dz-content"
                            >
                              <div className="dz-footer">
                                <h6 className="title text-truncate pb-2" style={{ fontWeight: 400 }}>{item.name}</h6>
                              </div>
                              <div className="dz-footer">
                                <div className="dz-meta mb-0">
                                  <span className={item?.added_services ? "badge badge-primary" : "badge badge-x-danger"}>
                                    {item?.added_services ? item?.added_services : 0} services
                                  </span>
                                </div>
                                <h6 className={item?.added_services ? "title mb-0" : "title mb-0 text-x-danger"}>€{item.revenue}</h6>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div>No properties found.</div>
                  )}
            </div>
          </Tab.Pane>
        </Tab.Content >
      </Tab.Container >
      <ModalVideo
        channel="youtube"
        autoplay={true}
        youtube={{ mute: 0, autoplay: 0 }}
        isOpen={openVideo}
        videoId="XJb1G9iRoL4"
        onClose={() => setOpenVideo(false)}
      />

      {
        addPropertyModal && (
          <>
            <Modal
              className="fade"
              show={setAddPropertyModal}
              onHide={() => setAddPropertyModal(false)}
              centered
            >
              <Modal.Header>
                <Modal.Title>Add Property</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setAddPropertyModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="row">
                    <div className="mb-12 col-lg-12 col-md-12 mb-2">
                      <label className="form-label">PMS Ical</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="airbnbIcalLink"
                          value={formData.airbnbIcalLink}
                          onChange={handleChange}
                        />
                        {errors.airbnbIcalLink && (
                          <div className="text-danger fs-12">
                            {errors.airbnbIcalLink}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-12 col-lg-12 col-md-12 mb-2">
                      <label className="form-label">Airbnb property link</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="airbnbPropertyLink"
                          value={formData.airbnbPropertyLink}
                          onChange={handleChange}
                        />
                        {errors.airbnbPropertyLink && (
                          <div className="text-danger fs-12">
                            {errors.airbnbPropertyLink}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-12 col-lg-12 col-md-12 mb-2">
                      <label className="form-label">E-Guide Link (optional)</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="pdfLink"
                          value={formData.pdfLink}
                          onChange={handleChange}
                        />
                        {errors.pdfLink && (
                          <div className="text-danger fs-12">
                            {errors.pdfLink}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setAddPropertyModal(false)}
                  variant="primary"
                >
                  {" "}
                  No{" "}
                </Button>
                <Button onClick={handleAddProperty}> Save </Button>
              </Modal.Footer>
            </Modal>
            {loading && (
              <div className="progress-overlay">
                <div className="progress-container">
                  <ProgressBar animated now={progress} label={`${progress}%`} />
                  <div className="progress-message">
                    Fetching data, please wait...
                  </div>
                </div>
              </div>
            )}
          </>
        )
      }
    </>
  );
};

export default PropertyList;
