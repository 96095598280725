import React, { Fragment, useEffect, useState } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

//** Import Image */
import profile from "../../../../images/avatar/1.jpg";
import PageTitle from "../../../layouts/PageTitle";
import PmService from "../../../../services/PmService";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../store/actions/AuthActions";
import Swal from "sweetalert2";
import { saveTokenInLocalStorage } from "../../../../services/AuthService";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../Loader";

const AppProfile = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  let errorsObj = {
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    profile_image: "",
    company_name: "",
    company_logo: "",
    lat_: "",
    long_: "",
    city: "",
    state: "",
    zip: "",
    fb_link: "",
    inst_link: "",
    website: "",
    telephone: "",
    telephone_2: "",
    about_me: "",
    bank_account: "",
    tax_ID: "",
    subscription: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event, name) => {
    console.log("name = ", name);
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: imageUrl,
      }));
      if (name == 'profile_image') {
        console.log("profile_image imageUrl = ", imageUrl)
        setLogo(file);
      } else if (name == 'company_logo') {
        console.log("company_logo imageUrl = ", imageUrl)
        setcompanyLogo(file);
      }
    }
  };

  useEffect(() => {
    setLoader(true);
    PmService.GetProfile()
      .then((response) => {
        if (response.success) {
          const {
            first_name,
            last_name,
            email,
            address,
            profile_image,
            lat_,
            long_,
            city,
            state,
            zip,
            fb_link,
            inst_link,
            website,
            telephone,
            telephone_2,
            about_me,
            bank_account,
            tax_ID,
            subscription,
            company_logo,
            company_name
          } = response.data;
          setFormData({
            first_name: first_name ?? "",
            last_name: last_name ?? "",
            email: email ?? "",
            address: address ?? "",
            profile_image: profile_image ?? "",
            lat_: lat_ ?? "",
            long_: long_ ?? "",
            city: city ?? "",
            state: state ?? "",
            zip: zip ?? "",
            fb_link: fb_link ?? "",
            inst_link: inst_link ?? "",
            website: website ?? "",
            telephone: telephone ?? "",
            telephone_2: telephone_2 ?? "",
            about_me: about_me ?? "",
            bank_account: bank_account ?? "",
            tax_ID: tax_ID ?? "",
            subscription: subscription?.plan ?? "",
            company_logo: company_logo ?? "",
            company_name: company_name ?? "",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 403 || error?.response?.status == 401) {
          Logout(navigate);
        }
      })
      .finally(function () {
        setLoader(false);
      });
  }, []);

 const handleSubmit = (e) => {
  e.preventDefault();

  // Replace null values with empty strings
  const sanitizedFormData = Object.fromEntries(
    Object.entries(formData).map(([key, value]) => [key, value ?? ""])
  );
  sanitizedFormData.image = logo;
  sanitizedFormData.lat = formData.lat_;
   sanitizedFormData.long = formData.long_;
  if (companyLogo != "") {
    sanitizedFormData.company_logo = companyLogo;
  }
  // Filter out entries with null or empty string values
  const filteredFormData = Object.fromEntries(
    Object.entries(sanitizedFormData).filter(
      ([key, value]) =>
        value !== null &&
        value !== "" &&
        key !== "email" &&
        key !== "subscription" &&
        key !== "profile_image" &&
        key !== "lat_" &&
        key !== "long_"
    )
  );

  // Validation function
  const validateFormData = (data,formdata) => {
    console.log(data);
    console.log(formdata);
    const errors = {};
    if (!data.first_name) errors.first_name = "First name is required";
    if (!data.last_name) errors.last_name = "Last name is required";
    if (!data.address) errors.address = "Address is required";
    if (!data.city) errors.city = "City is required";
    if (!data.state) errors.state = "State is required";
    if (!data.zip) errors.zip = "ZIP is required";
    if (!data.telephone) errors.telephone = "Telephone is required";
    if (!data.about_me) errors.about_me = "About me is required";
    if (!data.bank_account) errors.bank_account = "Bank account is required";
    if (!data.tax_ID) errors.tax_ID = "Tax ID is required";
    if (!data.company_name) errors.company_name = "Company name is required";
    if (!formdata.company_logo || formData.company_logo == "") errors.company_logo = "Company logo is required";
    
    // Add other validation rules as needed

    return {
      isValid: Object.keys(errors).length === 0,
      errors
    };
  };

  // Validate form data
  const { isValid, errors } = validateFormData(filteredFormData,sanitizedFormData);

  if (!isValid) {
    // Show error messages using Swal or any other method
    Swal.fire({
      icon: "error",
      title: "Update Profile Failed",
      html: Object.values(errors).map(error => `<p>${error}</p>`).join(''),
    });
    return;
  }

  console.log("filteredFormData = ", filteredFormData);
  PmService.UpdateProfile(filteredFormData)
    .then((resp) => {
      if (resp.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Details updated successfully!",
        }).finally(function () {
          const userDetails = JSON.parse(localStorage.getItem("userDetails"));
          if (resp.detail.first_name) {
            userDetails.first_name = resp.detail.first_name;
          }
          if (resp.detail.last_name) {
            userDetails.last_name = resp.detail.last_name;
          }
          if (resp.detail.profile_image) {
            userDetails.profile_image = resp.detail.profile_image;
          }
          saveTokenInLocalStorage(userDetails);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    })
    .catch((error) => {
      if (error?.response?.status == 403 || error?.response?.status == 401) {
        Logout(navigate);
      }
      if (error?.response?.data?.message?.length > 0) {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: error?.response?.data?.message,
        });
      }
    });
};

  const handleSubmitPasswordChange = (e) => {
    e.preventDefault();
    let error = true;
    const errorObj = { ...errorsObj };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!newPassword.trim()) {
      errorObj.newPassword = "New Password is required";
      error = false;
    } else if (!passwordRegex.test(newPassword)) {
      errorObj.newPassword =
        "New Password must be at least 8 characters long with one uppercase and one lowercase letter";
      error = false;
    }
    if (!confirmPassword.trim()) {
      errorObj.confirmPassword = "Confirm Password is required";
      error = false;
    } else if (confirmPassword !== newPassword) {
      errorObj.confirmPassword = "Confirm Password is not same as password.";
      error = false;
    }
    setErrors(errorObj);
    if (!error) {
      return;
    }
    const passData = {
      password: newPassword,
      confirm_password: confirmPassword,
    };
    PmService.ChangePassword(passData)
      .then((resp) => {
        console.log("response = ", resp);
        if (resp.success) {
          setOpenPasswordModal(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Password has been changed successfully.",
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  };
  return loader ? <Loader /> : (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              {/* <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div> */}
              <div className="profile-info">
                <div className="profile-photo" style={{ marginTop: "unset" }}>
                  <img
                    src={
                      formData.profile_image !== ""
                        ? formData.profile_image
                        : profile
                    }
                    alt="profile"
                    style={{
                      height: "80px",
                      width: "80px", // Ensure the width is equal to the height
                      borderRadius: "50%" // Make the image circular
                    }}
                  />
                </div>               
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {formData.first_name + " " + formData.last_name}
                    </h4>
                    <p>Property Manager</p>
                  </div>
                  {/* <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{formData.email}</h4>
                    <p>Email</p>
                  </div> */}
                
                  <div className="profile-subscription px-2 pt-2">
                    <h3>
                      <span className="badge badge-rounded badge-primary">
                        {formData.subscription}
                      </span>
                    </h3>
                  </div>
                </div>
                <div>
                  <Button
                    className="btn btn-primary rounded light"
                    onClick={() => {
                      setOpenPasswordModal(true);
                      setNewPassword("");
                      setConfirmPassword("");
                    }}
                  >
                    Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="settings-form">
                    <h4 className="text-primary">Account Setting</h4>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">First Name</label>
                          <span class="text-danger">*</span>
                          <input
                            placeholder="Enter first name"
                            className="form-control"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Last Name</label>
                          <span class="text-danger">*</span>
                          <input
                            placeholder="Enter last name"
                            className="form-control"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Email</label>
                          <span class="text-danger">*</span>
                          <input
                            disabled
                            placeholder="Enter email"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Profile Picture</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(event) => handleFileChange(event, 'profile_image')}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Company Name</label>
                           <span class="text-danger">*</span>
                          <input
                            placeholder="Enter company name"
                            className="form-control"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Company Logo</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(event) => handleFileChange(event, 'company_logo')}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">Address</label>
                        <span class="text-danger">*</span>
                        <input
                          type="text"
                          placeholder="Enter complete address"
                          className="form-control"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">
                            Latitude (Coordinates)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Latitude for map location"
                            name="lat_"
                            value={formData.lat_}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">
                            Longitude (Coordinates)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Longitude for map location"
                            name="long_"
                            value={formData.long_}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">City</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">State</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            id="inputState"
                            placeholder="Enter state"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Zip</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter zip code"
                            name="zip"
                            value={formData.zip}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Facebook</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your facebook link"
                            name="fb_link"
                            value={formData.fb_link}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Instagram</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your instagram link"
                            name="inst_link"
                            value={formData.inst_link}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Website</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Telephone 1</label>
                          <span class="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your phone number"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Telephone 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your phone number"
                            name="telephone_2"
                            value={formData.telephone_2}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">About me</label>
                          <span class="text-danger">*</span>
                          <textarea
                            className="form-control"
                            placeholder="Enter about your self"
                            rows="3"
                            name="about_me"
                            value={formData.about_me}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <h4 className="text-primary">Bank Setting</h4>
                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">Account Number</label>
                          <span class="text-danger">*</span>
                          <input
                            className="form-control"
                            placeholder="Enter bank account number"
                            rows="3"
                            name="bank_account"
                            value={formData.bank_account}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">Tax ID</label>
                          <span class="text-danger">*</span>
                          <input
                            className="form-control"
                            placeholder="Enter tax ID"
                            rows="3"
                            name="tax_ID"
                            value={formData.tax_ID}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3"></div>
                      <button className="btn btn-primary" type="submit">
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="fade"
        show={openPasswordModal}
        onHide={() => setOpenPasswordModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Change Password</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setOpenPasswordModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={() => { }}>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">New Password</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setErrors({
                        ...errors,
                        newPassword: "",
                      });
                    }}
                  />
                  {errors.newPassword && (
                    <div className="text-danger fs-12">
                      {errors.newPassword}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Confirm Password</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setErrors({
                        ...errors,
                        confirmPassword: "",
                      });
                    }}
                  />
                  {errors.confirmPassword && (
                    <div className="text-danger fs-12">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenPasswordModal(false)} variant="primary">
            {" "}
            No{" "}
          </Button>
          <Button onClick={handleSubmitPasswordChange}> Save </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AppProfile;
